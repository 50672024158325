// @flow
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { auth } from 'utils'

function CustomRoute({
  component: Component,
  isPrivate = false,
  isLogin = false,
  isProtected = false,
  ...rest
}: {
  component: React.Node,
  isPrivate?: boolean,
  isLogin?: boolean,
  isProtected?: boolean,
  rest?: Object
}) {
  return (
    <Route
      {...rest}
      render={(props?: Object) => {
        const isAuth = auth.isValidToken()
        if (isPrivate) {
          if (!isAuth) {
            return <Redirect to={{ pathname: '/' }} />
          }

          const isAdmin = auth.isAdmin()
          if (isProtected && !isAdmin) {
            return <Redirect to={{ pathname: '/' }} />
          }
        }

        if (isLogin && isAuth) {
          return <Redirect to={{ pathname: '/inicio' }} />
        }

        return <Component {...props} />
      }}
    />
  )
}

export default CustomRoute
