import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#9c27b0'
    },
    secondary: {
      main: '#ffb300'
    }
  }
})
