// @flow
import React from 'react'
import {
  Box,
  Typography,
  FormControl,
  FormHelperText,
  MenuItem,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { DatePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import AsyncSelect from 'react-select/async'

import {
  TextField,
  Button,
  LoadingButton,
  Snackbar,
  AmountField,
  LabelValue
} from 'components'
import { loanScheme } from 'schemas'
import { useGet, usePost } from 'hooks'
import { fetch } from 'api'
import { getFee, formatAmount } from 'utils'
import { constants } from 'config'

const { initialValues, schema } = loanScheme.create

function AddLoans() {
  const history = useHistory()
  const { data: loanPropsData, loading: getLoading, error: getError } = useGet(
    'loan/props'
  )
  const { post, loading, error, isError } = usePost()

  if (getError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {getError}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(isError && error)}
        type='error'
        message={typeof error === 'string' ? error : error && error.message}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values: Object) =>
          post('loan/create', values, (loan: Object) => {
            window.open(
              `${constants.baseUrl}/docs/creditfacilito/contract/${loan.id}`
            )
            history.push(`/inicio/prestamos/detalle/${loan.id}`)
          })
        }
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          values,
          submitCount
        }: {
          errors: Object,
          touched: Object,
          setFieldValue: Function,
          setFieldTouched: Function,
          values: Object,
          submitCount: number
        }) => {
          const { expectedAmount } = getFee(
            Number(values.amount || 0),
            Number(values.interestPercentage || 0),
            Number(values.capitalPercentage || 0),
            Number((values.periodicity && values.periodicity.times) || 0),
            Number(values.penaltyFee || 0)
          )

          return (
            <Form>
              <Card>
                <CardHeader title='Préstamo' />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                      <Field name='clientId'>
                        {({ field: { name } }: { field: Object }) => (
                          <FormControl fullWidth margin='normal'>
                            <AsyncSelect
                              name={name}
                              styles={{
                                container: () => ({
                                  zIndex: 999
                                })
                              }}
                              cacheOptions
                              placeholder={
                                (values.client && values.client.name) ||
                                'Buscar cliente...'
                              }
                              noOptionsMessage={() =>
                                'No se encontro ningun resultado.'
                              }
                              loadingMessage={() => 'Buscando...'}
                              getOptionLabel={(option: Object) =>
                                `${option.name} - ${option.idNumber}`
                              }
                              getOptionValue={(option: Object) => option.id}
                              loadOptions={(inputValue: string) =>
                                fetch.get(`client/nameOrIdNumber/${inputValue}`)
                              }
                              onChange={(client: Object) => {
                                setFieldValue('client', client)
                                setFieldValue(name, client.id)
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true)
                              }}
                            />
                            {Boolean(
                              touched.clientId && errors.clientId && submitCount
                            ) && (
                              <FormHelperText error>
                                {errors.clientId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Field name='userId'>
                        {({ field: { name } }: { field: Object }) => (
                          <FormControl fullWidth margin='normal'>
                            <AsyncSelect
                              styles={{
                                container: () => ({
                                  zIndex: 998
                                })
                              }}
                              name={name}
                              cacheOptions
                              placeholder={
                                (values.user && values.user.name) ||
                                'Buscar cobrador...'
                              }
                              noOptionsMessage={() =>
                                'No se encontro ningun resultado.'
                              }
                              loadingMessage={() => 'Buscando...'}
                              getOptionLabel={(option: Object) =>
                                `${option.name} - ${option.idNumber}`
                              }
                              getOptionValue={(option: Object) => option.id}
                              loadOptions={(inputValue: string) =>
                                fetch.get(`user/nameOrIdNumber/${inputValue}`)
                              }
                              onChange={(user: Object) => {
                                setFieldValue('user', user)
                                setFieldValue(name, user.id)
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true)
                              }}
                            />
                            {Boolean(
                              touched.userId && errors.userId && submitCount
                            ) && (
                              <FormHelperText error>
                                {errors.userId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='amount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto*'
                            value={value}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='loanTypeId'>
                        {({ field: { name, value } }: { field: Object }) => {
                          if (getLoading) {
                            return (
                              <Box display='flex' justifyContent='center'>
                                <CircularProgress />
                              </Box>
                            )
                          }

                          return (
                            <TextField
                              select
                              name={name}
                              label='Tipo*'
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                const loanTypeSelected = loanPropsData.loanTypes.find(
                                  (type: Object) => type.id === value
                                )
                                setFieldValue('loanType', loanTypeSelected)
                                setFieldValue(name, value)

                                if (loanTypeSelected.type === constants.FIXED) {
                                  setFieldValue('capitalPercentage', '')
                                }
                              }}
                              error={Boolean(
                                touched.loanTypeId && errors.loanTypeId
                              )}
                              helperText={
                                touched.loanTypeId && errors.loanTypeId
                              }
                            >
                              {(loanPropsData &&
                                loanPropsData.loanTypes &&
                                loanPropsData.loanTypes.map(
                                  ({
                                    id,
                                    name
                                  }: {
                                    id: number,
                                    name: string
                                  }) => (
                                    <MenuItem key={id} value={id}>
                                      {name}
                                    </MenuItem>
                                  )
                                )) || <Box />}
                            </TextField>
                          )
                        }}
                      </Field>
                    </Grid>
                    <Field name='interestPercentage'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (!values.loanType) {
                          return null
                        }

                        if (getLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <TextField
                              select
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target

                                setFieldValue(name, value)
                              }}
                              label='Porcentaje al intereses*'
                              error={Boolean(
                                touched.interestPercentage &&
                                  errors.interestPercentage
                              )}
                              helperText={
                                touched.interestPercentage &&
                                errors.interestPercentage
                              }
                            >
                              {(loanPropsData &&
                                loanPropsData.loanInterestPercentages &&
                                loanPropsData.loanInterestPercentages.map(
                                  (percentage: number) => (
                                    <MenuItem
                                      key={percentage}
                                      value={percentage}
                                    >
                                      {`${percentage}%`}
                                    </MenuItem>
                                  )
                                )) || <Box />}
                            </TextField>
                          </Grid>
                        )
                      }}
                    </Field>

                    <Field name='capitalPercentage'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (
                          !values.loanType ||
                          values.loanType.type === constants.FIXED
                        ) {
                          return null
                        }

                        if (getLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <TextField
                              select
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                setFieldValue(name, value)
                              }}
                              label='Porcentaje al capital*'
                              error={Boolean(
                                touched.capitalPercentage &&
                                  errors.capitalPercentage
                              )}
                              helperText={
                                touched.capitalPercentage &&
                                errors.capitalPercentage
                              }
                            >
                              {(loanPropsData &&
                                loanPropsData.loanCapitalPercentages &&
                                loanPropsData.loanCapitalPercentages.map(
                                  (percentage: number) => (
                                    <MenuItem
                                      key={percentage}
                                      value={percentage}
                                    >
                                      {`${percentage}%`}
                                    </MenuItem>
                                  )
                                )) || <Box />}
                            </TextField>
                          </Grid>
                        )
                      }}
                    </Field>

                    <Field name='loanPeriodicityId'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (!values.loanType) {
                          return null
                        }

                        if (getLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <TextField
                              select
                              name={name}
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                const periodicitySelected = loanPropsData.loanPeriodicities.find(
                                  (periodicity: Object) =>
                                    periodicity.id === value
                                )

                                setFieldValue(
                                  'periodicity',
                                  periodicitySelected
                                )
                                setFieldValue(name, value)
                              }}
                              label='Periodicidad de pago*'
                              error={Boolean(
                                touched.loanPeriodicityId &&
                                  errors.loanPeriodicityId
                              )}
                              helperText={
                                touched.loanPeriodicityId &&
                                errors.loanPeriodicityId
                              }
                            >
                              {(loanPropsData &&
                                loanPropsData.loanPeriodicities &&
                                loanPropsData.loanPeriodicities.map(
                                  ({
                                    id,
                                    name
                                  }: {
                                    id: number,
                                    name: string
                                  }) => (
                                    <MenuItem key={id} value={id}>
                                      {name}
                                    </MenuItem>
                                  )
                                )) || <Box />}
                            </TextField>
                          </Grid>
                        )
                      }}
                    </Field>

                    <Field name='payday'>
                      {({ field: { value } }: { field: Object }) => {
                        const { loanPeriodicityId } = values

                        if (!loanPeriodicityId) {
                          return null
                        } else if (
                          loanPeriodicityId !== values.lastPeriodicityId
                        ) {
                          const payday = moment().add(
                            values.periodicity && values.periodicity.value,
                            values.periodicity && values.periodicity.periodicity
                          )

                          setFieldValue('lastPeriodicityId', loanPeriodicityId)
                          setFieldValue('payday', payday)
                          setFieldValue('lastPayday', payday)
                          setFieldValue('penaltyFee', '')
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <DatePicker
                              margin='normal'
                              inputVariant='outlined'
                              fullWidth
                              label='Fecha del proximo pago'
                              format='DD/MM/YYYY'
                              value={value}
                              onChange={(date: Date) => {
                                setFieldValue('payday', date)
                                setFieldValue('penaltyFee', '')
                              }}
                            />
                          </Grid>
                        )
                      }}
                    </Field>

                    <Field name='penaltyFee'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (
                          !values.payday ||
                          !loanPropsData ||
                          !values.loanPeriodicityId
                        ) {
                          return null
                        }

                        if (
                          moment(values.payday).isSame(values.lastPayday, 'day')
                        ) {
                          return null
                        }

                        const diff = moment(values.payday)
                          .startOf('day')
                          .diff(values.lastPayday.startOf('day'), 'days')

                        return (
                          <Grid item sm={4} xs={12}>
                            <AmountField
                              name={name}
                              label={`Monto de penalización por ${diff} dia${
                                diff > 1 ? 's.' : '.'
                              }`}
                              value={value}
                              onValueChange={(values: Object) => {
                                setFieldValue(name, values.value)
                              }}
                              error={Boolean(
                                touched.penaltyFee && errors.penaltyFee
                              )}
                              helperText={
                                touched.penaltyFee && errors.penaltyFee
                              }
                            />
                          </Grid>
                        )
                      }}
                    </Field>
                    <Grid item xs={12}>
                      {values.amount &&
                        values.interestPercentage &&
                        values.loanPeriodicityId && (
                          <LabelValue
                            label='Monto de la Cuota'
                            type='number'
                            value={formatAmount(expectedAmount)}
                          />
                        )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <LoadingButton loading={loading}>
                    <Button disabled={loading}>Agregar</Button>
                  </LoadingButton>
                </CardActions>
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default AddLoans
